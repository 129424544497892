<template>
    <b-row>
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
            <div class="mb-3">
                <div class="mt-3 mb-1 d-flex align-items-center">
                <router-link :to="getPath()">
                        <b-icon icon="arrow-left" class="mr-2"></b-icon>
                        <span v-if="$route.params.returnType == 'shift_card'">{{trans('back-to-service',200)}}</span>
                        <span v-else-if="$route.params.returnType == 'registrations_list'">{{trans('to-registration-list',200)}}</span>
                        <span v-else-if="$route.params.returnType == 'offers_list'">{{trans('back-to-job-offer',200)}}</span>

                        <span v-else-if="last_route.name == 'planning-shifts-profile'">{{trans('back-to-service',200)}}</span>
                        <span v-else-if="last_route.name == 'planning-registrations'">{{trans('to-registration-list',200)}}</span>
                        <span v-else-if="last_route.name == 'planning-offers'">{{trans('back-to-job-offer',200)}}</span>
                        <span v-else>{{trans('back-to', 200)}} {{trans(last_route.meta.name_nl, 264)}}</span>
                </router-link>
                </div>
            </div>

            <b-alert variant="warning" show class="mb-3" v-if="planningParametersCheck != null">
                <div class="d-flex w-100 justify-content-between">
            
                    <div class="text-md-start mt-2">{{trans('planning-scheduling-information-not-entered',211)}}</div> 
                    <div class="d-flex align-items-center">
                        <app-button type="primary" :url="{ name: 'clients-profile-organization', params: { id_client_data_main: planningParametersCheck }}">{{trans('to-organization',175)}}</app-button>
                    </div>
                </div>
            </b-alert> 
      
            <b-row class="mb-3">
                <b-col>
                    <offers-shifts-person-add-search-component :idsShiftDataMain.sync="ids_shift_data_main" :person.sync="person" :startDate.sync="start_date" :loadingListShift.sync="loading_list_shift" :loadingListPlanner.sync="loading_list_planner" />
                </b-col>
            </b-row>
            
            <b-row class="mb-3" v-if="person != null">
                <b-col>
                    <planning-planner-person-add-schedule-component :person.sync="person" :startDate.sync="start_date" :fromListPersonAdd="shiftsData" :loadingListPlanner.sync="loading_list_planner"/>
                </b-col>
            </b-row>
            
            <b-row class="mb-3" v-if="person != null">
                <b-col>
                    <offers-shifts-person-add-shifts-list-component  :idsShiftDataMain="ids_shift_data_main" :loading.sync="loading" :person.sync="person" :shiftsData.sync="shiftsData" :planningParametersCheck.sync="planningParametersCheck" :loadingListShift.sync="loading_list_shift"/>
                </b-col>
            </b-row>
            <div class="mt-4 mb-3" >
                <app-button type="primary" @click="registerPerson()" :loading="loading" :disabled="person == null || posibleClick || planningParametersCheck != null">
                    {{trans('planning-register',210)}}
                </app-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsPersonAddSearchComponent from './Components/OffersShiftsPersonAddSearchComponent.vue';
import OffersShiftsPersonAddShiftsListComponent from './Components/OffersShiftsPersonAddShiftsListComponent.vue';
import PlanningPlannerPersonAddScheduleComponent from '@app/Planning/Planner/PlanningPlannerPersonAddScheduleComponent.vue';

export default {
    components: {
        OffersShiftsPersonAddSearchComponent,
        OffersShiftsPersonAddShiftsListComponent,
        PlanningPlannerPersonAddScheduleComponent
    },

    watch: {

    },

    computed: {
        posibleClick(){
           var count = this.shiftsData.filter(field => field.status < 0).length
           if(count > 0){
            return true;
           }else{
            return false;
           }

        }
    
    },

    data(){
        return {
            ids_shift_data_main: this.$route.params.ids_shift_data_main,
            start_date: this.$route.params.start_date,
            planningParametersCheck: null,
            shiftsData: [],
            person: null,
            alertClass: new AppAlerts(),
            client_confirmed: null,
            loading: false,
            loading_list_planner: false,
            loading_list_shift: false,
            last_route: JSON.parse(localStorage.getItem('routeStack'))[JSON.parse(localStorage.getItem('routeStack')).length - 1] 
        }
    },

    methods:{   
        
        registerPerson(){
            var badShifts = this.shiftsData.filter(field => field.status < 0).length
            
            if(badShifts > 0){
                this.alertClass.openAlertWarning(this.trans('planning-cannot-add-person-to-service',211));
                return;
            }
            
            this.loading = true;

                    axios
                        .post('planning/shifts/person/register', {
                            shifts: JSON.stringify(this.shiftsData),
                            id_person_data_main: this.person.id
                        })
                        .then((res) => {
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            if(this.$route.params.returnType == 'shift_card'){
                                this.$router.push({ name: "planning-shifts-profile", params: { id_shift_data_main: this.$route.params.returnIdType } });
                            }else if(this.$route.params.returnType == 'registrations_list'){
                                this.$router.push({name: "planning-registrations", params: { } });
                            }else if(this.$route.params.returnType == 'offers_list'){
                                this.$router.push({ name: "planning-offers", params: { } });
                            }else if(this.last_route.name == 'planning-shifts-profile'){
                                this.$router.push({ name: "planning-shifts-profile", params: { id_shift_data_main: this.last_route.params.id_shift_data_main } });
                            }else{
                                this.$router.push({ name: this.last_route.name, params: { }});
                            }
                        });
        },

        getPath(){
            if(this.$route.params.returnType == 'shift_card'){
                return '/planning/shifts/profile/' + this.$route.params.returnIdType;
            }else if(this.$route.params.returnType == 'registrations_list'){
                return '/planning/registrations';
            }else if(this.$route.params.returnType == 'offers_list'){
                return '/planning/offers';
            }else{
                return this.last_route.path;
            }
        },
    }
}
</script>

<style scoped>

</style>