<template>
    <b-card no-body class="app-card-profile">
        <b-overlay :show="loading_list" :opacity="0.6">
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div>{{trans('schedule',3)}} - {{ person.name }}</div>
                    </div>
                </b-col>
                <b-col cols="auto" class="pl-0" style="z-index: 1;" end>
                    <app-date-week-selector :listType="2" :onlyWeek="true" :changeArrows="true" :weekDates.sync="filters.week" :key="refresh_component" :clearable="false"/>
                </b-col>
                <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="refresh" :loading.sync="loading_list"/>
                </b-col>
            </b-row>
            <b-row class="mt-3" v-if="plan != null">
                <b-col>
                    
                    <div class="app-planner-container">
                        <div class="app-planner-weeks-header">
                            <planning-planner-timeline-header-component :isWeekHeader="true" :minute_pixel_width.sync="minute_pixel_width" :week="week" v-for="week in planner_properties.weeks" :key="week.index" :selected_week.sync="filters.week" :read_only="read_only"/>
                        </div> 

                        <div class="app-local-planner-person-row app-planner-person-row-selected border-0" v-if="plan != null">    
                            <div class="app-planner-person-plan-row-blocks">
                            <planning-planner-blocks-component 
                                                                    :person.sync="plan"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="0"
                                                                    :dates="planner_properties.weeks[0].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                                                    :fromListPersonAdd="fromListPersonAdd"
                                />

                                <planning-planner-blocks-component 
                                                                    :person.sync="plan"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="1"
                                                                    :dates="planner_properties.weeks[1].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                                                    :fromListPersonAdd="fromListPersonAdd"
                                />  

                                <planning-planner-blocks-component 
                                                                    :person.sync="plan"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="2"
                                                                    :dates="planner_properties.weeks[2].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                                                    :fromListPersonAdd="fromListPersonAdd"
                                                                    
                                />
                            </div>             
                        </div>      
                    </div>
                </b-col>
            </b-row>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </b-card>
</template>


<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerTimelineHeaderComponent from './Components/PlanningPlannerTimelineHeaderComponent.vue';
import PlanningPlannerBlocksComponent from './Components/PlanningPlannerBlocksComponent.vue';


export default {
    name: 'PlanningPlannerPersonProfileComponent',

    components: {
        PlanningPlannerTimelineHeaderComponent,
        PlanningPlannerBlocksComponent,
    },

    created(){
        this.getList(); 
        this.onCreateThis();
    },

    computed: {       
    },

    props: {
        person: { },     
        startDate: { },
        fromListPersonAdd: {type: Array, default() { return [] }},
    },

    mounted() {
    
    },

    data() {
       return {

             filters : {
                type: 0,
                search: '',
                week: moment(this.startDate, 'DD-MM-YYYY').format('YYYY-W'),
                weeks_count: 3,
                office: null,
                show_registered: true
            },

            hovered_block: {
                type: null,
                id: null
            },

            edited_availability: {
                week_index: null,
                item: null,
                id_person_data_main: null
            },

            popover_week_statistics: {
                show: false,
                target: 'popover-week-statistics',
                values: {
                    hours: 0,
                    worked: 0,
                    notavailable: 0,
                    diff: 0
                }
            },
            mUnavailability: { show: false, args: { id_person_data_main: null}, result: 0, return: null},
            minute_pixel_width: null,
            loading: false,
            loading_list: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            plan: null,
            statistics: {
                total: 0,
                week: [
                    {},
                    {},
                    {},
                    {}
                ]
            },
            
            planner_properties: {
                dates: [],
                weeks: []                
            },

            refresh: 0,
            refresh_component: 0,
            read_only: true
        };
    },
    
    methods: {
        
        getList() {
            
            this.loading_list = true;

            if(this.filters.week == null || this.filters.week == ''){
                this.filters.week = moment().format('YYYY-W');
                this.refresh_component++;
            }
            axios
                .post("planning/planner/getPlan", {
                    filters: JSON.stringify(this.filters),
                    id_person_data_main: this.person.id
                })
                .then((res) => {
                    this.plan = res.data.people[0];
                    this.plan.selected = true;
                    this.calculateDatesAndWeeks(res.data.start_date);
                    //this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                        this.loading_list = false;
                })

        },

        calculateDatesAndWeeks(startDate){

            let start_date = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
            let start_week = moment(startDate, 'YYYY-MM-DD');

            this.planner_properties.weeks = [];

            for(let w=0; w<3; w++){

                let dates = [];
                
                for(let d=0; d<7; d++) {
                    start_date = moment(start_date, 'DD-MM-YYYY').isoWeekday(d+1);
                    let label = start_date.locale('nl').format('dd');
                    label = label.charAt(0).toUpperCase() + label.slice(1);
                    
                    dates.push({
                                    index: d,
                                    day_name: label, 
                                    short_date: start_date.format('DD-MM'), 
                                    date: start_date.format('DD-MM-YYYY'),
                                    is_today: start_date.format('DD-MM-YYYY') == moment().format('DD-MM-YYYY'),
                                    is_weekend: start_date.isoWeekday() < 6 ? false : true
                                });
                    
                    
                }

                start_date = start_date.add(1, 'weeks').format('DD-MM-YYYY');

                this.planner_properties.weeks.push( {
                                                    index: w,
                                                    week_no: start_week.format('W'),
                                                    week: start_week.format('YYYY-W'), 
                                                    label: this.trans('week',3) + ' ' + start_week.format('W'),
                                                    dates: dates,
                                                    is_selected: this.filters.week == start_week.format('YYYY-W') ? true : false
                                                });

                start_week = start_week.add(1, 'weeks');   
 
            }

                
            
            
                
        },

        onCreateThis() { 
            //this.loadSettings();

            this.$watch('refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.edited_availability.id_person_data_main = null;
                    this.edited_availability.item = null;
                    this.edited_availability.week_index = null;
                    
                    this.getList();
                }   
            });

            this.$watch('filters.week', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('person', function(newVal, oldVal) {
                if (newVal != null && !this.loading_list) {
                    this.getList();
                }   
            });
        },

        onCloseUnavailabilityList(){
            this.getList();
        }
        
    },

    
};
</script>
<style scoped>
</style>